import { Auth0ContextInterface } from '@auth0/auth0-react';

const authProvider = ({
  isAuthenticated,
  loginWithRedirect,
  logout,
  user,
}: Auth0ContextInterface) => ({
  login: loginWithRedirect,
  logout: () => {
    if (isAuthenticated) {
      logout({ returnTo: window.location.origin });
    }
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => (isAuthenticated ? Promise.resolve() : Promise.reject()),
  getPermissions: () => Promise.reject('Unknown method'),
  // getIdentity: () => Promise.resolve(),
});

export default authProvider;
