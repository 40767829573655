import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CssBaseline from '@mui/material/CssBaseline';
import fakeDataProvider from 'ra-data-fakerest';
import { Admin, Resource } from 'react-admin';

import authProvider from 'src/utils/authProvider';

const Login = React.lazy(() => import('src/views/Login'));
const Users = React.lazy(() => import('src/views/Users'));

const dataProvider = fakeDataProvider({
  users: [
    { id: 0, title: 'Hello, world!' },
    { id: 1, title: 'FooBar' },
  ],
});

export default function App() {
  const auth0 = useAuth0();
  const auth = authProvider(auth0);

  return (
    <>
      <CssBaseline />
      <React.Suspense fallback={null}>
        <Admin
          disableTelemetry
          authProvider={auth}
          dataProvider={dataProvider}
          loginPage={Login}
          title="Morty dasho"
        >
          <Resource name="users" list={Users} />
        </Admin>
      </React.Suspense>
    </>
  );
}
